<template>
	<div>
		<common-table
				:itemScope="['lsell', 'couponType', 'dataTj']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				pageTitle="优惠券大类"
				:btnActionEvent="btnActionEvent"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>
			<template v-slot:dataTj="{ item }">
				<v-btn depressed color="primary" @click="dataTj(item)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					数据统计
				</v-btn>
			</template>

			<template v-slot:couponType="{ item }">
				<v-btn depressed color="primary" @click="couponType(item.code)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					小类
				</v-btn>
			</template>
		</common-table>
		<exhibition-data-box :dialog.sync="tj_dialog" title="数据统计" :width="400" :contentHeight="260">
			<div class="d-flex mt-4">
				<range-time
						:dense="true" :hideDetails="true" :notAllowedYesterday="true" @change="changeTime"
						:value="tj_time"/>
				<v-btn class="ml-5" depressed color="primary" @click="search1">查询</v-btn>
			</div>
			<div class="d-flex flex-wrap mt-5">
				<div class="col-6">
					<v-text-field label="领取总数" readonly :value="tj_form.totalNum || 0"/>
				</div>
				<div class="col-6">
					<v-text-field label="领取总金额" readonly :value="tj_form.totalPrice || 0"/>
				</div>
				<div class="col-6">
					<v-text-field label="核销总数" readonly :value="tj_form.checkNum || 0"/>
				</div>
				<div class="col-6">
					<v-text-field label="核销总金额" readonly :value="tj_form.checkPrice || 0"/>
				</div>
			</div>
		</exhibition-data-box>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/coupon.js';
import editorText from "@/components/editorText/editorText.vue";
import rangeTime from '@/components/dateTime/rangeTime.vue';
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box.vue";
import moment from "moment";
import rangeTimePicker from "@/components/timePicker/rangeTimePicker.vue";
import commonTitle from "./common-title.vue";

export default {
	mixins: [commonCurd],
	components: {exhibitionDataBox, rangeTime},
	data() {
		return {
			api,
			tj_time: '',
			tj_form: {},
			tj_code: '',
			tj_dialog: false,
			laccountnum: 0,
			ldailynum: 0,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: ''
			},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			// 定义右侧按钮
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入大类代码/名称', property: 'searchkey', width: '200px'}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			// 表格头部字段
			headers: [
				{text: '大类代码', value: 'code'},
				{text: '大类名称', value: 'description'},
				{text: '每日领取金额上限', value: 'limitprice'},
				{text: '排序号', value: 'seq'},
				{text: '预订状态', value: 'lsell'},
				{text: '小类', value: 'couponType'},
				{text: '数据统计', value: 'dataTj'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "优惠券大类代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "优惠券大类名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTime,
						property: ["startget", "endget"],
						// 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
						deconstruction: function (args) {
							return args.split(" ~ ")
						},
						connector: {
							props: {
								required: true,
								label: "领取日期范围",
								notAllowedTodayBefore: true
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTime,
						property: ["startdate", "enddate"],
						// 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
						deconstruction: function (args) {
							return args.split(" ~ ")
						},
						connector: {
							props: {
								required: true,
								label: "有效日期范围",
								notAllowedTodayBefore: true
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTimePicker,
						property: 'timeRange',
						connector: {
							props: {
								required: true,
								startText: '每日领取时段',
								endText: ''
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'limitprice',
						connector: {
							props: {
								label: "每日领取金额上限",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					{
						cols: 12,
						subassembly: commonTitle,
						connector: {
							props: {
								required: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'laccountnum',
						connector: {
							props: {
								label: "用户账号领取数量",
								type: 'number',
								required: true,
								rules: [(v) => {
									this.laccountnum = v
									if (!v || v === 0) {
										return '用户账号领取数量不能为空'
									} else if (v && parseInt(v) < this.ldailynum) {
										return '用户每日领取数量不能大于用户账号领取数量'
									}
									return true
								}]
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'ldailynum',
						connector: {
							props: {
								label: "用户每日领取数量",
								type: 'number',
								required: true,
								rules: [(v) => {
									this.ldailynum = v
									if (!v || v === 0) {
										return '用户每日领取数量不能为空'
									} else if (v && parseInt(v) > this.laccountnum) {
										return '用户每日领取数量不能大于用户账号领取数量'
									}
									return true
								}]
							}
						}
					},
					{
						cols: 12,
						subassembly: commonTitle,
						connector: {
							props: {
								title: '核销账号限制',
								subTitle: '限制可核销账号的名单，使用,进行分割，不填不限制'
							}
						}
					},
					{
						cols: 12,
						subassembly: "VTextField",
						property: 'lcheckrole',
						connector: {
							props: {
								label: "可核销账号"
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '使用说明'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'richtext',
						connector: {
							props: {
								multi: 2
							}
						}
					}
				]
			}
		}
	},
	methods: {
		couponType(item) {
			this.$router.push({name: 'couponType', query: {value: item}});
		},
		dataTj(item) {
			this.tj_dialog = true
			this.tj_form = {}
			this.tj_time = moment().format('YYYY-MM-DD') + ' ~ ' + moment().format('YYYY-MM-DD')
			this.tj_code = item.code
			this.search1()
		},
		changeTime(v) {
			this.tj_time = v
		},
		search1() {
			if (!this.tj_time) {
				this.snackbar.error('请选择查询时间')
				return false
			}
			let startDate = '', endDate = ''
			let arr = this.tj_time.split(' ~ ');
			startDate = arr[0]
			endDate = arr[1]
			this.axios.post('/api/coupon/get_coupongroup_useData', {
				startDate: startDate,
				group: this.tj_code,
				endDate: endDate
			}).then(res => {
				this.tj_form = res.data.groupDetail
			})
		},
		beforeSave(form) {
			if (!this.globalMethods.isEmpty(form.timeRange)) {
				let array = form.timeRange.split(" ~ ");
				form.starttime = array[0];
				form.endtime = array[1];
			}
		},
		beforeEdit(form) {
			if (!this.globalMethods.isEmpty(form.starttime) && !this.globalMethods.isEmpty(form.endtime)) {
				form.timeRange = form.starttime + " ~ " + form.endtime;
			}
		}
	}
}
</script>
