<template>
	<div class="d-flex mb-4">
		<div :class="{'asterisk-div': required}" style="font-size: 18px; font-weight: 700">{{ title }}</div>
		<div class="ml-2" style="font-size: 14px; color: #999999">（{{ subTitle }}）</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '领取数量限制'
		},
		subTitle: {
			type: String,
			default: '限制用户账号及每日领取的数量，数值需大于0'
		},
		required: Boolean
	}
}
</script>

<style scoped>

</style>
